html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.no-text-decoration {
  text-decoration: none;
}

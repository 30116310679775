@font-face {
  font-family: 'Bolton-Regular';
  src: local('Bolton-Regular'), url('/fonts/Bolton-Regular.woff2') format('woff2'),
    url('/fonts/Bolton-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Bolton-Medium';
  src: local('Bolton-Medium'), url('/fonts/Bolton-Medium.woff2') format('woff2'),
    url('/fonts/Bolton-Medium.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Bolton-Bold';
  src: local('Bolton-Bold'), url('/fonts/Bolton-Bold.woff2') format('woff2'),
    url('/fonts/Bolton-Bold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Thin'),
  url('/fonts/Roboto-Thin.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Regular'),
  url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Bold'),
  url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
}

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

body {
  padding: 0;
  margin: 0 0 0 0;
}

em {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-style: normal;
  font-display: block;
  margin: 0;
  margin: 0;
  display: block;
  font-size: 1em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p {
  margin: 0;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  line-height: 1em;
  padding: 0;
  margin: 0;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: none;
  text-shadow: 0px 0px 0px transparent;
  text-align: left;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button:focus {
  border: none;
  outline: none;
}

button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

figcaption {
  opacity: 0.6;
  font-size: 12px;
  text-align: center;
}

.dark-mode {
  background: #1f1f1f;
}
